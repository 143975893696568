.filter-by-org {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

    .menu {
      left: -100px !important;
      right: 0;
    }

    .selected-org-label {
      line-height: unset !important;
    }

    .no-orgs-found {
      padding: 10px;
      text-align: center;
    }
}