.organisation {
    .slds-dropdown.slds-dropdown_left {
        transition: all 0.25s ease-in-out;
    }

    .sfmc-insights{
      margin: 10px 0;

      details {
        margin-top: .5rem;

        summary {
          cursor: pointer;
          outline: none;
          &:hover {
            color: #005fb2;
          }
          &::-webkit-details-marker {
            color: #0070d2;
          }
        }

        ul {
          margin-top: .5rem;
          padding-left: 1rem;
        }
      }
    }
}

.no-results-found {
    padding: 10px;
    text-align: center;
    font-size: medium;
    font-weight: 700;
  }

  .filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;

      .filter-button {
        width: fit-content;
      }
  }

  .selected-filter-label {
    line-height: unset !important;
  }

  .filter-inputs {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    margin: 5px 0;

    .ui.labeled.input {
      margin-right: 10px;
    }
  }

  .modal {
    top: auto !important;
    left: auto !important;
    height: 16rem;

    .text {
      color: black !important;
      font-weight: 600 !important;
    }

    .ui.loader:after {
      border-color: orange transparent transparent !important;
    }
  }
