
#trainings-self-learning-modal {
  #modal-heading-01 {
    margin-top: 0;
  }
}

.delete-self-learning-item {
  margin-left: 17px;
  left: 0;
}

#modal-content-self-learning {
  min-height: 16.7rem;


  .multi-select {
    .slds-listbox_selection-group {
      min-height: 2.1rem;
      height: max-content;
    }

    #listbox-id-7 {
      max-height: 8rem;
    }
  }
}

.self-learning-input-search-container {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin: 0.5rem 0;
  height: 2rem;

  .left {
    .icon {
      font-size: 1.5rem !important;
      width: 2.5rem !important;
    }
  }

  .search {
    height: 2rem;
  }

  .slds-select_container {
    margin-left: 0.25rem;
  }
}

.tr-topic-questions {
  th:nth-child(2),th:nth-child(3) {
    width: 18%;
  }

  th:nth-child(4), th:nth-child(5) {
    width: 15%;
  }
}

.tr-modules {
  th:nth-child(2),th:nth-child(3) {
    width: 20%;
  }
}

.tr-topics, .tr-self-learning-overview {
  th:nth-child(2),th:nth-child(3), th:nth-child(4) {
    width: 15%;
  }
}

.tr-self-learning-customer-questions {
  th:nth-child(2), th:nth-child(3),th:nth-child(4), th:nth-child(5) {
    width: 15%;
  }

  th:nth-child(7) {
    text-align: center;
  }

  th:nth-child(8) {
    width: 6rem;
    text-align: center;
  }
}

.self-learning-overview-body-table, .self-learning-customer-questions-body-table {
  .slds-truncate {
    white-space: pre-wrap;
  }
}

.self-learning-customer-questions-body-table {
  td:nth-child(7) {
    text-align: center;

    label {
      width: 1rem;
    }
  }

  td:nth-child(8) {
    text-align: center;
  }
}

#certification-user-modal {
  #listbox-id-7 {
    max-height: 6.5rem;
  }

  .slds-listbox_selection-group {
    height: 2rem;
  }
}

#customer-questions-self-learning-modal, #training-results-self-learning-modal {
  input:read-only, textarea:read-only {
    background-color: #f3f2f2;
    padding: 0 1rem 0 0.75rem;

    &:focus, &:active, &:focus-visible {
      border: 1px solid #dddbda;
      box-shadow: none;
    }
  }

  .answered-checkbox {
    width: 5rem;

    .slds-checkbox_faux {
      margin-right: 10px;
    }
  }

  #form-element-question {
    min-height: 200px;
    cursor: default;
  }
}

#training-results-self-learning-modal {
  .slds-modal__container {
    max-width: 60rem;
  }

  tbody, thead {
    border-top: 1px solid #dddbda;
    border-bottom: 1px solid #dddbda;

    th {
      background-color: #fafaf9;
      color: #514f4d;
      font-weight: 700;
      line-height: normal;
    }

    td, th {
      padding: 5px;
    }
  }
}

.total-results {
  border:none !important;

  td {
    font-weight: 700;
    color: #514f4d;
  }
}

.self-learning-customer-questions, .self-learning-overview {
  .filter {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;

    .filter-button {
      width: fit-content;
    }
  }

  .filter-data-label {
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }

  .date-filter-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0,0,0,.87);

    input {
      margin-left: 10px;
      width: calc(100% - 10px) !important;
      height: 1.85rem !important;
    }
  }

  .checkbox-label, .filter-by-date-label {
    background-color: white !important;
    border: 1px solid rgba(34,36,38,.15) !important;
    padding: .67857143em 1em;
    margin: 0 !important;
    color: rgba(0,0,0,.87) !important;
    font-size: 1em !important;

    .ui.dropdown, .menu{
      width: 7.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .menu {
      top: 1.5rem;
      width: 9.1rem;
      left: -0.78rem;
    }
  }

  .selected-filter-label {
    line-height: unset !important;
    display: flex !important;
    justify-content: space-between;

    p:first-child {
      margin-bottom: 0 !important;
    };

    p:first-letter {
      text-transform:capitalize;
    }

    > div {
      margin-right: 10px;
    }
  }

  .filter-inputs {
    .ui.basic.label {
      display: flex;
      align-items: center;
      min-width:auto;
      font-size: 1em;
    }

    .ui.label {
      display: flex;
      align-items: center;
      margin-right: 0;
      border-right: none !important;
      border-radius: 0 !important;
      min-width:9rem;

      .ui.dropdown {
        width: 7.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .ui.right.labeled.input {
      margin-right: 0px;
      min-width: 9rem;

      input {
        border-radius: 0 !important;
      }
    }
  }
}

.trash-delete-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#topics-self-learning-modal {
  .slds-modal__container {
    max-width: 80rem;
    width: 80%;
    padding: 2rem 0;
  }
}

#customer-questions-self-learning-modal {
  .slds-modal__container {
    max-width: 60rem;
    width: 80%;
    padding: 2rem 0;
  }

  .send-reply {
    button {
      margin-top: 10px;
    }
  }
};