.form-container{
    margin: 50px auto;
}

.form-container textarea{
    font-size: 17px;
}

.Active-switch{
    float: left;
    width: 150px;

    &--disabled{
        color: #ccc;
        .material-switch > label{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.Active-label{
    float: left;
    font-size: 17px;
    margin-right: 20px;
}

.save-search-btn{
    &:disabled{
        cursor: not-allowed;
    }
}

.material-switch > label > input[type="checkbox"] {display: none;}
.material-switch > label {cursor: pointer;height: 0px;position: relative;width: 40px;background: #007bff;}
.material-switch > label::before {background:rgb(131, 131, 131);box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);border-radius: 8px;content: '';height: 16px;margin-top: -8px;position:absolute;opacity: 0.7;transition: all 0.4s ease-in-out;width: 40px;right: 0;}
.material-switch > label::after {background: rgb(255, 255, 255);border-radius: 16px;box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);content: '';height: 24px;left: -4px;margin-top: -8px;position: absolute;top: -4px;transition: all 0.3s ease-in-out;width: 24px;}
.material-switch > label.material-switch-active::before {background: inherit;}
.material-switch > label.material-switch-active::after {background: inherit;left: 20px;}
