.login-box {
	display: flex;
	width: 90vw;
	height: 90vh;
	margin: auto;
}

.login {
	background-color: #ffffff;
	width: 500px;
	height: 500px;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-o-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 20px 0px #dbdbdb;
	-moz-box-shadow: 0px 0px 20px 0px #dbdbdb;
	-o-box-shadow: 0px 0px 20px 0px #dbdbdb;
	box-shadow: 0px 0px 20px 0px #dbdbdb;
}

.login-top-text {
	font: 31px Roboto-Regular;
	margin-top: 50px;
}

.login-logo {
	font: 25px Roboto-Thin;
	margin-bottom: 40px;
	text-align: center;
	line-height: 1.5;
}

.login-logo img {
	width: 300px;
	font: 25px Roboto-Thin;
	margin-bottom: 20px;
	text-align: center;
	line-height: 1.5;
}

.login-account {
	font: 14px Roboto-Light;
	margin-top: 40px;
	text-align: center;
}

.icon-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.login-form form .username-icon {
	width: 25px;
	height: 25px;
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	/*margin-top: 10px;*/
}

.login-form form .eye-icon {
	width: 25px;
	height: 25px;
	position: absolute;
	margin-left: 270px;
}

.login-form form .email,
.login-form form .password {
	border-radius: 0;
	border: 0;
	border-bottom: 0.5px solid #000;
	padding-left: 30px;
	width: 300px;
}

.login-form form .password {
	border-radius: 0;
	border: 0;
	border-bottom: 0.5px solid #000;
	padding-left: 30px;
	padding-right: 35px;
	width: 300px;
}

.login-form form .login-btn {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	border: 0;
	width: 300px;
	height: 40px;
	background-color: #FFCC00;
	color: #000066;
	margin-top: 30px;
	font-weight: bold;
}

.login-form form .login-btn:hover {
	background-color: #ffdb4a;
}

.login-form form .social-btn {
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	border: 0;
	width: 135px;
	height: 40px;
	background-color: #f2f4fa;
	color: #000066;
	margin: 15px;
	font-weight: bold;
}

.login-form form .social-btn:hover {
	background-color: #d4d6dd;
}


.form-group {
	margin-bottom: 30px;
}

.form-check {
	margin-bottom: 30px;
}

.login-form form .email:focus,
.password:focus {
	box-shadow: 0 0 0;
}

.remember-me-label {
	font-family: Roboto-Light;
}
