.sun-editor-editable {
    padding-bottom: 6rem;
}

.when-pressed {
    margin-left: 1rem;
}

.save-topic {
    min-width: 7rem;

    .preview-loader-container {
      position: relative;
    }

    .slds-spinner {
        left: 0;
    }
}

#toggle-button-label {
    width: fit-content;
}

#checkbox-toggle-topic-content {
    display: flex;

    .slds-checkbox_on, .slds-checkbox_off {
        margin-left: 5px;
    }

}
