.hubspot-icon {
  height: 20px;
  padding-left: 10px;
}

.organisation-header {
  height: 40px;
  background-color: #e1e3e2 !important;
  color: var(--black) !important;
} 

.id-header {
  font-size: 15px;
  background-color: #e1e3e2 !important;
  color: var(--black) !important; 
  text-align: right;
}

#adoption-tabs{
  display: flex;

  .tab {
      font-size: 20px;
      color: #5e85ae;
      margin-right: 0.1rem;
      line-height: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem 0.5rem 0rem 0rem;
      border-color: var(--gray);
      border-width: 1px;
      border-style: hidden;
      border-bottom: 0px;
      cursor: pointer;
  }

  .active {
      background-color: #dee7ee;
      border-style: solid;
  }
}

.buSection {
  height: 200px;
  overflow-y: 'scroll';
  margin-top: 0.5rem;
}

.tableSection {
  font-size: 12px;
}

.mainColumn {
  width: 200px; 
}

.rankColumn {
  width: 50px;
}

.totalRow {
 background-color: #f3f2f2;
 color: #080707;
}

#adoption-tabs{
  display: flex;
  .tab {
      font-size: 20px;
      color: #5E85AE;
      margin-right: 0.1rem;
      line-height: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem 0.5rem 0rem 0rem;
      border-color: var(--gray);
      border-width: 1px;
      border-style: hidden;
      border-bottom: 0px;
      cursor: pointer;
  }
  .active {
      background-color: #DEE7EE;
      border-style: solid;
  }
}

.organisation-header {
  height: 40px;
  background-color: #E1E3E2 !important;
  color: var(--black) !important;
}
.id-header {
  font-size: 15px;
  background-color: #E1E3E2 !important;
  color: var(--black) !important;
  text-align: right;
}


.buSection {
  height: 200px;
  overflow-y: 'scroll';
  margin-top: 0.5rem;
}

.tableSection {
  font-size: 12px;
}

.mainColumn {
  width: 200px; 
}

.rankColumn {
  width: 50px;
}

.totalRow {
 background-color: #f3f2f2;
 color: #080707;
}

#adoption-tabs{
  display: flex;
  .tab {
      font-size: 20px;
      color: #5E85AE;
      margin-right: 0.1rem;
      line-height: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem 0.5rem 0rem 0rem;
      border-color: var(--gray);
      border-width: 1px;
      border-style: hidden;
      border-bottom: 0px;
      cursor: pointer;
  }
  .active {
      background-color: #DEE7EE;
      border-style: solid;
  }
}

.organisation-header {
  height: 40px;
  background-color: #E1E3E2 !important;
  color: var(--black) !important;
}
.id-header {
  font-size: 15px;
  background-color: #E1E3E2 !important;
  color: var(--black) !important;
  text-align: right;
}


.buSection {
  height: 200px;
  overflow-y: 'scroll';
  margin-top: 0.5rem;
}

.tableSection {
  font-size: 12px;
}

.mainColumn {
  width: 200px; 
}

.rankColumn {
  width: 50px;
}

.totalRow {
 background-color: #f3f2f2;
 color: #080707;
}

.detailedReportSize {
  height: 300px;
}