#access-to-certification-modal {
    max-width: 60rem;
    min-width: 50rem;

    .slds-modal__content:last-child {
        box-shadow: none;
    }

    .exam-modal-questions-table {
        height: 100%;

        .exam-modal-table {
            height: 100%;
        }

        tbody {
            tr:last-child {
                td {
                    border-bottom: 1px solid #dddbda;
                }
            }
        }
    }
}

.isAdmin-info-tooltip {
    color: #706e6b;
    margin-left: 5px !important;

    &:hover {
        color: #005fb2;
    }
}

.isAdmin-popup {
    z-index: 99999 !important;
    background-color: #16325c !important;
    color: white !important;
    font-size: 0.9rem !important;

    &::before {
        background-color: #16325c !important;
    }
}
