@import 'variables';

.dashboard {

    .category {
        font-size: 22px;

        // Caracteres before the title, ex: • Title
        &::before {
            content: '• ';
        }

        &:not(:first-child) {
            border-top: 1px solid #ddd;
            padding-top: 25px;
        }
    }

    // Margins between boxes
    .slds-grid:not(:first-child) {
        margin-top: $box-margin-vertical;
    }
    .slds-grid:last-child {
        margin-bottom: $box-margin-vertical;
    }
    [class*="slds-size_"]:not(:first-child) > .slds-box {
        margin-left: $box-margin-horizontal;
    }
    .slds-grid.no-margin {
        margin-top: 0 !important;
    }

    .slds-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: $box-color;
        height: 100%;
        overflow: hidden;

        .slds-has-flexi-truncate {
            flex: auto;
        }

        .box-title {
            font-size: 20px;
            font-weight: 500;
            height: 50px;
        }

        .box-content {  
            font-size: 12px;
            font-weight: 400;

            &.chart {
                height: 200px;
            }

            &.number {
                font-size: 40px;
                font-weight: 700;
                width: 100%;
                text-align: center;
            }

            &.list {
                margin-top: 0.5rem;
                overflow-y: scroll;
            }
            &.list .no-data {
                height:100px;
            }

            & .no-data {
                color: red;
                height: 100%;
            }
        }

        .box-footer {
            width: 100%;
            height: 25px;
        }

        // Show/Hide org list button
        .extends {
            width: 100%;
            
            .slds-icon {
                fill: black;
                margin-top: 10px;
            }
        }
    }
}