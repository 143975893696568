#otherDefaultValue {
    width: unset;
}

.alert-table {
    margin-bottom: 10px;
    font-size: 12px;
}

.slds-form-text {
    margin-top: 1em;
    font-size: 1rem;
}

.slds-form-element__label_highlight {
    font-weight: 600;
    font-size: 0.8rem;
}

.info_icon {
    float: right;
    margin-bottom: 5px;
    color: #0070d2;
    cursor: pointer;
    text-decoration: underline;
}

.slds-form-element__checkbox {
    font-size: 13px;

    &__input {
        vertical-align: middle;
    }

    &__label {
        margin-left: 1em;
    }
}

.specific-org-container {
    display: flex;

    &.expand_selection {
        height: 100px;
    }

    .slds-form-element__checkbox {
        flex-basis: 25%;
    }

    .ui.selection.dropdown {
        flex-basis: 75%;
        align-self: flex-start;

        [role='option'] {
            font-size: 12px;
        }
    }

    .ui.disabled.search {
        opacity: .75;
    }
}

.alert-template-dialog {
    position: relative;
    border: none;
    background: #f0f8ff;
    padding: 10px 0px;

    .icon {
        cursor: pointer;
    }

    &__image {
        width: 60%;
        display: block;
        margin: 0 auto;
    }
}
