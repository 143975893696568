@import 'variables';

.dashboard {
    /* https://github.com/IanLunn/Hover */
	@-webkit-keyframes hvr-bob {
		0% {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
		}
		50% {
			-webkit-transform: translateY(-4px);
			transform: translateY(-4px);
		}
		100% {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
		}
	}
	@keyframes hvr-bob {
		0% {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
		}
		50% {
			-webkit-transform: translateY(-4px);
			transform: translateY(-4px);
		}
		100% {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
		}
	}
	@-webkit-keyframes hvr-bob-float {
		100% {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
		}
	}
	@keyframes hvr-bob-float {
		100% {
			-webkit-transform: translateY(-8px);
			transform: translateY(-8px);
		}
	}
	.hvr-bob {
		display: inline-block;
		vertical-align: middle;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	}
	.hvr-bob:hover {
		-webkit-animation-name: hvr-bob-float, hvr-bob;
		animation-name: hvr-bob-float, hvr-bob;
		-webkit-animation-duration: .3s, 1.5s;
		animation-duration: .3s, 1.5s;
		-webkit-animation-delay: 0s, .3s;
		animation-delay: 0s, .3s;
		-webkit-animation-timing-function: ease-out, ease-in-out;
		animation-timing-function: ease-out, ease-in-out;
		-webkit-animation-iteration-count: 1, infinite;
		animation-iteration-count: 1, infinite;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
		-webkit-animation-direction: normal, alternate;
		animation-direction: normal, alternate;
	}
    /* https://github.com/IanLunn/Hover END */

    
    .slds-grid {
        transition: margin-top $animation-duration $animation-timing;
    }

    .expandable {
        max-height: 0;
        padding: 0 $box-margin-horizontal;
        border: 0px solid transparent;
        
        transition: all $animation-duration $animation-timing;
    }

    .expandable.expanded {
        max-height: 100vh;
        padding: $box-margin-vertical $box-margin-horizontal;
        border: 1px solid #dddbda;
    }
}

