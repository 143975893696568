.highlight-deal-prop {
    color: red;
    font-size: 16px;
}

.notification {
    font-size: 16px;
}

.invoicing-progress {
    background-color: white;
}