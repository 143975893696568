@import "~video-react/styles/scss/video-react";

.slds-modal__container.feature-adverts-container{
  max-width: initial;
  width: 60%;

  .new-feature-advert-btn{
    margin-bottom: 1em;
    float: right;
  }
}

.slds-modal__container.feature-advert-modal{
  max-width: initial;
  width: 50%;

  .img-rel-container{
    img{
      height: 100% !important;
    }
  }
  .feature-media{
    margin-top: 2em;
  }

  .update-feature-btn{
    display: flex;
    justify-content: flex-end;
  }
}

/* Switch */
.status-container{
  display: flex;
  justify-content: flex-end;

  .status-label{
    margin-right: 1em;
  }
}
