
.certification-user-selection-wrapper {
  display:flex;
  flex-wrap:wrap;

}

.certification-user-selection-box {
  border: 1px solid #dddbda;
  width: fit-content;
  padding-left: 0.25rem;
  border-radius: 0.25rem;
  margin-top:0.2rem;

  >span {
    margin-right: 0.3rem;
  }
}

.certifications-users {
  tr {
    th:nth-child(5) {
      width: 15%;
    }

    th:nth-child(2), th:nth-child(3), th:nth-child(6) {
      width: 12%;
    }
  }

  .certifications-organisation-filter-container {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding-right: 1.5rem;

    .selected-filter-label {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    .slds-select_container {
      width: 14rem;
      margin-left: auto;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;

      &.hide {
        visibility: hidden;
        height: 0;
      }
    }

    .multi-select-container-with-filter-label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .slds-listbox_selection-group {
      font-size: 1rem;
      padding-right: 0;
      height: 3rem;
      display: flex;
      justify-content: flex-end;
    }

    .filter-container-with-listbox {
      display: flex;
      justify-content: flex-end;

      .slds-pill__remove {
        cursor: pointer;
        margin-right: 0;
        margin-left: 0.5em;
        font-size: .92857143em;
        opacity: .5;
        transition: background .1s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.generated-exams {
  tr {
    th:nth-child(2), th:nth-child(3) {
      width: 18%;
    }
  }
}

.tr-certifications-questions {
  th:nth-child(2),th:nth-child(3), th:nth-child(4) {
    width: 20%;
  }
}

.certifications-questions-body-table {
  td:nth-child(2), td:nth-child(4) {
    div {
      white-space: pre-line;
    }
  }

  td:nth-child(3) {
    div {
      li {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.select-questions {
  cursor: pointer;
  background-color: #f3f2f2;
  margin-top: 1rem;
  padding: 0.5rem;
  width: fit-content !important;

  &:hover {
    color: #005fb2;
  }

  &.active {
    color: #005fb2;
  }
}

.select-question-modal {
  min-width: 80vw;
  min-height:100vh;

  td {
    label {
      overflow: auto;
      white-space: initial;
    }
  }
}

.exam-modal-questions-table {
  display: flex;
  height: 60vh;
}

.questions-for-select-checkbox, .selected-questions-checkbox {
  opacity: 0;
}

.exam-modal-table {
  border: 1px solid #f3f2f2;
  border-radius: 5px;
  height: 100%;

  tr {
    height: 3rem;
  }

  .no-item {
    margin: auto;
    margin-top: 50px;
    font-weight: bold;
    text-align: center;
  }
}

.selected-questions-table {
  margin-left: 2rem;

  thead {
    th {
      background-color: white;
      text-align: center;
    }
  }

  tbody {
    tr, td {
      background-color: #f3f2f2;
      display: block;
      height: 3rem;

      &:last-child {
        td {
          border-bottom: 1px solid #dddbda;
        }
      }

      td {
        display: block;
        width: 100%;
      }

      &:hover {
        background-color: white !important;
      }
    }

      label {
        cursor:pointer;
        width: 100%;
        padding: 0.5rem 0;
        margin-bottom: 0 !important;
    }
  }
}

.question-for-select-table {
  margin-right: 2rem;

  thead {
    th {
      text-align: center;
    }
  }

  tbody {
    tr {
      display: block;
      height: 3rem;
      background-color: white;

      &:last-child {
        td {
          border-bottom: 1px solid #dddbda;
        }
      }

      td {
        display: block;
        width: 100%;
        background-color: white;
        height: 100%;

        &:hover {
          background-color: #f3f2f2 !important;
        }
      }

      &:hover {
        background-color: #f3f2f2 !important;
      }
    }
  }

  label {
    cursor:pointer;
    width: 100%;
    padding: 0.5rem 0;
    margin-bottom: 0 !important
  }
}

.select-question-box {
  min-height:50px;

  .slds-spinner {
    left: 5%;
    top: 55%;
  }
}

.answers-list {
  list-style: decimal;
  margin-left: 2rem;
}

.add-answer-button {
  height: 30px;
  padding: 0 0.5rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
}

.add-answer-input {
  width: 50%;
}

.answers-list-in-questions {
  list-style: decimal;
}

.certifications-input-search-container {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0;
  height: 2rem;

  .left {
    .icon {
      font-size: 1.5rem !important;
      width: 2.5rem !important;
    }
  }
}

.exam-success {
  fill: #04844b;
}

.exam-failed {
  fill: #f50808;
}

.export {
  fill: #343a40;
  width: 1.2rem;
  height: 1.2rem;
}