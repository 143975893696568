.add-questions-wrapper {
    padding-top: 10px;
}

.add-questions-button {
    margin-left: 10px;
    height: 27px;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #0070d2;
    border: solid 1px #dddbda;
    background: #fff;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: var(--primary-bg-color);
    }

    &:focus {
        outline: none;
      }

}

.add-questions-input {
    height: 30px;
    width: 100%;
    margin-right: 5px;
}

.questions-list {
    list-style-type: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    justify-content: flex-start;
    background: whitesmoke;
    overflow-y: auto;
    border-radius: 5px;
    padding: 0.5rem 0;
}

.question-item {
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.5rem;
}

.question-icon-button-box {
    margin-right: 0.5rem;
    display: flex;
    width: 100%;
    padding: 0.2rem 0;
    overflow-wrap: anywhere;
    align-items: center;

    p {
        margin-bottom: 0;
        width: 100%;
        font-size: 14px;
    }
}

.icon-button-add-questions, .icon-button-add-related-links {
    margin-left: 10px;
    height: 27px;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #fff;
    border: solid 1px #dddbda;
    background: #fff;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: var(--primary-bg-color);
    }

    i {
        margin: 0 2px !important;
        color: #0070d2;
        height: 1rem !important;
        width: 1.5rem !important;
    }

    &:focus {
        outline: none;
      }
}

.icon-button-add-related-links {
    margin: 0 1rem;

    &.remove {
        margin-right: 0px;
        margin-left: 2rem;
    }
}

.edit-link-button {
  margin-right: 1rem;
}

.add-questions-input {
    &.link{
        width: calc(100% - 8rem);
    }
}

.input-button-add-question {
    display: flex;
    align-items: center;
}

.related-links-label {
    display: flex;
    justify-content: space-around;
    font-size: 1rem;
    width: 100%;
}