.nav-vertical_container {
    .slds-nav-vertical {
        .slds-nav-vertical__action {
            transition: box-shadow 0.15s ease-in;
        }

        .slds-nav-vertical__title {
            background-color: var(--secondary-bg-color);
            color: white;
        }

        & :hover::before {
            background-color: whitesmoke;
        }

        .slds-is-active {
            &.slds-nav-vertical__item::before {
                background-color: whitesmoke;
            }
        }
    }
    background-color: white;
    height: 100vh;
    overflow: auto;
}