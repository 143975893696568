:root {
	--primary-bg-color: #f2f4fa;
	--secondary-bg-color: #494949;
}

* {
	margin: 0;
	padding: 0;
}

html,
body {
	background-color: var(--primary-bg-color) !important;
}

.main-container {
	width: 100%;

	.slds-gutters {
		margin-right: 0 !important;
	}

	.slds-form-element__label {
		width: 100%;
	}

	input[type="date"] {
		width: 100%;
		height: calc(1.875rem + 2px);
		padding-left: 0.5rem;
		padding-right: 0.25rem;
		border-radius: 0.25rem;
		border: 1px solid #dddbda;
	}

	.id-column {
		width: 200px;
	}

	.name-column {
		width: 200px;
	}
}

.welcome-text {
	padding: 25px;
	text-align: center;
}

// Override Lighting Design setting
// Make label font-size bigger (defaults to 0.75 rem)
.slds-form-element__label {
	font-size: 1rem !important;
}

// Override SemanticUI setting
button, input, select, textarea {
	font-size: 13px !important;
}